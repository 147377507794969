document.addEventListener( 'DOMContentLoaded', function () {
  const resourceSection = document.querySelector( '.e-stn--resource-center-featured .e-col' );
  
  const domLoadClass = document.querySelector( '.e-stn--featured-resources-section-dom-load' );
    
  if ( domLoadClass ) {
    domLoadClass.classList.remove( 'e-stn--featured-resources-section-dom-load' );
  }

  if ( resourceSection ) {
    resourceSection.classList.add( 'col-lg-6' );

    // Clone the column
    const clonedColumn = resourceSection.cloneNode( true );
        
    // Add 'col-lg-6' to the cloned column
    clonedColumn.classList.add( 'col-lg-6' );
        
    // Handle the first column (original): Keep only the first 'e-sgp' and remove the rest
    const firstColumnItems = resourceSection.querySelectorAll( '.e-sgp' );
    firstColumnItems?.forEach( ( item, index ) => {
      if ( index !== 0 ) {
        item.remove();
      }
      // Remove 'e-sgp--featured' and add 'e-sgp--dlt'
      item.classList.remove( 'e-sgp--featured' );
      item.classList.add( 'e-sgp--dlt' );
    } );

    // Add 'e-div--left-content-wrapper' class to the immediate div of the first column
    const firstColumnImmediateDiv = resourceSection.querySelector( 'div' );
    if ( firstColumnImmediateDiv ) {
      firstColumnImmediateDiv.classList.add( 'e-div--left-content-wrapper' );
    }

    // Handle the second column (clone): Remove the first 'e-sgp' and keep the rest
    const secondColumnItems = clonedColumn.querySelectorAll( '.e-sgp' );
    secondColumnItems?.forEach( ( item, index ) => {
      if ( index === 0 ) {
        item.remove();
      } else {
        // Remove 'e-sgp--featured' and add 'e-sgp--dlt'
        item.classList.remove( 'e-sgp--featured' );
        item.classList.add( 'e-sgp--dlt' );
      }
    } );

    // Add 'e-div--right-content-wrapper' class to the immediate div of the second column
    const secondColumnImmediateDiv = clonedColumn?.querySelector( 'div' );
    if ( secondColumnImmediateDiv ) {
      secondColumnImmediateDiv.classList.add( 'e-div--right-content-wrapper' );
    }

    resourceSection.parentNode.appendChild( clonedColumn );
  }
} );
