function positionIcon( footerForm ) {
  if ( !footerForm ) return;

  const emailInput = footerForm.querySelector( 'input[type="email"]' );
  const arrow = footerForm.querySelector( '.hs-submit' );

  if ( emailInput && arrow ) {
    const formTop = footerForm.getBoundingClientRect().top;
    const inputTop = emailInput.getBoundingClientRect().top;
    const inputHeight = emailInput.offsetHeight;
    const relativeTop = ( inputTop - formTop ) + inputHeight / 2 ;
    arrow.style.top = relativeTop + 'px';
  }
}

window.addEventListener( 'message', ( event ) => {
  const currentDomain = window.location.origin;

  if ( event.origin !== currentDomain ) {
    return;
  }

  if ( event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormReady' ) {
    const formID = event.data.id;
    const footerForm = document.querySelector( `footer form.hs-form[data-form-id="${formID}"]` );

    if ( footerForm ) {
      positionIcon( footerForm );

      const resizeObserver = new ResizeObserver( ( ) => {
        positionIcon( footerForm );
      } );

      resizeObserver.observe( footerForm );

      footerForm.addEventListener( 'remove', () => {
        resizeObserver.disconnect();
      } );
    }
  }
} );